
.icons{
    font-size: 20px;
    margin-left: 5px;
}
.delete{
    color: #ec3131db;
}
.shop-floor-value{
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
}
.shop-floor-description{
    overflow: hidden;
    /* width: 100px; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 15px;
}
// .card{
//     height: 200px !important;
// }

.items{
    border-radius: 5;
    padding: 5px;
    border: 1px solid #fff;
    margin: 5px;
}
.cardItems{
    height: 10px;

}
.equipmentListItems{
    min-height: 50px;
     color: #7b7b7b;
    span{
        border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 6px;
    background-color: #f1a6a6;
    width: 100%;
    color: #414141;
    }
}
.itemIcons{
    font-size: 25px;
    margin-left: 5px;
    margin-right: 10px;
    cursor: pointer;
}
.itemAlignRight{
    display: flex;
    justify-content: center;
}
.boldNames{
    font-weight: 500;
    color: #7b7b7b;
}
input{
    color: '#7b7b7b';
}
.modalLabel{
    font-weight: 500;
}
.table-bordered {
    border: 2px solid #737272; /* Black border for the entire table */
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd; /* Lighter border for table cells */
  }
  
  .table-bordered tbody tr:hover {
    background-color: #f1f1f1; /* Optional: Change the background on row hover */
  }
  .addNewButton{
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 20px;
  }
  .btn-close {
    color: #fff; 
    opacity: 1;
}
.itemSlides{
    font-size: 12px;
    line-height: 14px;
    display: inline;
}
.formListItem{
    padding: 5px;
    border-radius: 12px;
}
.formListItemdefaultBorder{
  border:2px solid $light-red

}
.formListItemRedBorder{
  border: 2px solid #a98a8a;

}
.form-check-input{
    width: 25px !important;
    height: 25px !important;
}
.listItem{
        border-bottom: 2px solid #cccccc9c;
}
.optionList{
    overflow-y: scroll;
    max-height: 45vh;
    scrollbar-width: none;
}
#formTime{
    max-width: 150px;
    display: inline-block;
    margin-right: 20px;
}
.schedule-label{
  color:$light2-grey;
  font-size: 16px;
  font-weight: bold;
}
.schemas,.scheduleForm{
    .daz-switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 21px !important;
      
        input { 
            opacity: 0;
            width: 0;
            height: 0;
          }
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #7B7B7B;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          .slider:before {
            position: absolute;
            content: "";
            height: 19px !important;
            width: 19px !important;
            left: 1px !important;
            bottom: 1px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          input:checked + .slider {
            background-color: #44F055;
          }
          
          input:focus + .slider {
            box-shadow: 0 0 1px #44F055;
          }
          
          input:checked + .slider:before {
            -webkit-transform: translateX(19px);
            -ms-transform: translateX(19px);
            transform: translateX(19px);
          }  
          .slider.round {
            border-radius: 34px;
          }
          
          .slider.round:before {
            border-radius: 50%;
          }
          input:disabled + .slider {
            cursor: initial;
          }
      }
      .btn-success{
        border:2px solid $green;
        color: $green;
        background-color: white;
      }
      .btn-secondary{
        color: $dark2-grey;
        background-color: white;
      }
      .semi-bold,.itemSlides,.editIcon{
        color:$dark2-grey ;
      }
      .active{
        color: $dark2-grey;
      }
   
}
.shopfloor-row{
  border-bottom: 1px solid $light2-grey !important;
}
.shopfloor {
  color:$light2-grey !important
}

.schemas{
  .card_icons{
    font-size: 30px;
  }
  .edit_icon{
    color:$dark-green
  }
  .add_icon{
    color:$dark-green
  }
  .cards{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .schedule_name{
    max-width: fit-content;
    max-height: 45px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    
  }
  .day_button{
    flex: 1;
    border: 2px solid $dark2-grey;
    color: $dark2-grey;
    max-width: 65px;
  }
  .day_button_container{
    justify-content: space-between;
  }
  .btn-success{
    border:2px solid $green;
    color: $green;
    background-color: white;
  }
  .btn-secondary{
    color: $dark2-grey;
    background-color: white;
  }
  .daz-switch{
    width: 40px !important;
  }
}
@keyframes blink-border {
  0% {
    border-color: transparent;
  }
  50% {
    border: 5px solid;
    border-color: red;
  }
  100% {
    border-color: transparent;
  }
}

.flashing-border {
  border: 5px solid transparent; /* Set the border to be transparent initially */
  animation: blink-border 1s infinite; /* Blink the border every second */
}
.slideShowPlay{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: white;
  

}
.slideShowContainer {
  // margin: 40px auto;
  // max-width: 600px;
  .default_card{
    border: 3px solid $dark-green;
    box-shadow:$dark-green 0px 0px 0px
    // border-color: $;
  }
}
.element {
  width: 100%;
  // background-color: skyblue;
  text-align: center;
  color: white;
  // font-size: 3em;
}
.element:-ms-fullscreen p {
  visibility: visible;
}
.element:fullscreen {
  background-color: #e4708a;
  width: 100vw;
  height: 100vh;
}
.customiseModalForm{
  min-height: 80vh;
  .sidebar-slider{
    width: 100% !important;
  }
}
.shopfloorForm{
.modal-dialog{
  min-width: 600px;
}

}
.scheduleForm{
  .modal-dialog{
    min-width: 70vw;
    .formListItem{
      min-height: 70px;
      display: flex;
      align-items: center;
    }
    .day_button{
      flex: 1;
      border: 2px solid $dark2-grey;
      color: $dark2-grey;
    }
    .day_button_container{
      justify-content: space-between;
    }
    .btn-success{
      border:2px solid $green;
      color: $green;
      background-color: white;
    }
    .btn-secondary{
      color: $dark2-grey;
      background-color: white;
    }
  }
  
  }
  .shopfloor-quotes{
    font-size: 14px;
    span{
      font-size: 12px;
      font-weight: 500;
    }
  }
  .schemas{
    .linkAccordion .accordion-header button::after{
display: none;
    }
    .material-icons{
      cursor: pointer;
      &:hover{
        font-size: 32px
      }
    }
   
  }
  .emission-factor-view{
    .shopfloor-previous-list{
      max-height: 100vh !important;
      background-color: white !important;
    }
  }
  .blink_me {
    animation: blinker 1s linear infinite;
  }
  .card-title{
    padding-bottom: 15px;
    height: fit-content;
  }
  @keyframes blinker {  
    50% { opacity: 0; }
  }
