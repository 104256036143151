.daz-sidebar{
    background-color:$grey-bg;
    max-width: 125px;
    text-align: center;
    position: relative;
    
    &__dropdown-wrp{
        position: relative;
    }

    &__iconwrap{
        padding: 10px;
        display: block;
        text-decoration: none;
        color:$dark2-grey;
        position: relative;
        
        span{
            color: #7d7d7d;
            font-weight: $font-light;
            font-size: 15px;
        }

        &:hover,&--active{
            cursor: pointer;
            text-decoration: none;
            color:$dark2-grey;

            i {
            color:#9cd094;
                background:$green-gradient-icn;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }    
        }

        &--disabled{
            pointer-events: none;
            color: $dark-grey;
        }

        i{
            display: block;
            font-size: 40px;          
        }
    }

    &__hoverwrp:hover{
                background-color: $grey-bg;

        .daz-sidebar__hoverinfo{
            //display: block;
            left: 100%;
            transition: left 0.1s ease;
            // padding-top: 10px;
            // padding-bottom:10px ;
            
            .daz-sidebar__dropdown--item:hover{
                background-color: $grey-bg;

            }
        }
        .daz-sidebar__hoverinfo:hover{
            background-color: $grey-bg;
        }
    }

    &__dropdown-hov-left{
        display: flex!important;
        align-items: center;
        justify-content: space-between;
        line-height: 1.1;

        &::before{
        content: "arrow_left";
        font-family: "Material Icons";
        font-size: 30px;
        color: $dark2-grey;
        }

    }

    &__dropdown-hov{
        display: flex!important;
        align-items: center;
        justify-content: space-between;
        line-height: 1.1;
        min-width: 200px;
        &:hover{
            background-color: $grey-bg;
        }

        &::after{
        content: "arrow_right";
        font-family: "Material Icons";
        font-size: 30px;
        color: $dark2-grey;
        position: absolute;
        right: 0;
        padding-left: 10px;
        }

    }
    .daz-sidebar__dropdown-wrp:hover{
        background-color: $grey-bg;
            
        // > .daz-sidebar__dropdown-hov::after{
        //     content: "arrow_drop_up";
        //     color: rgba(123, 123, 123, 0.5);
        // }
    }
    &__dropdown-click{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        line-height: normal;

        &::before{
            content: "arrow_left";
            font-family: "Material Icons";
            font-size: 30px;
            color: $dark2-grey;
        }
        &.active::before{
            content: "arrow_left";
          //  color: rgba(123, 123, 123, 0.5);
        }
    }

    &__dropdown{
        display: none;
        max-height: 492px;
        overflow: auto;
        // margin-left: 10px;
//        padding: 10px;
        &--item{
            display: flex;
            align-items: center;
            // padding-top: 5px;
            // padding-bottom: 5px;
        }
        &--disabled{
            pointer-events: none;
            opacity: 0.5;
            cursor: default !important;
        }
    }
    &__dropdown-wrp{
        > .daz-sidebar__hoverinfo--item > a{
        //   font-weight: $font-bold; 
          color: $dropdown-txt-grey; 
          white-space: nowrap;
        }
    }
    &__dropdown-wrp-right{
        > .daz-sidebar__hoverinfo--item > a{
        //   font-weight: $font-bold; 
          color: $dropdown-txt-grey; 
          white-space: nowrap;
        }
    }
    &__dropdown-wrp-right:hover{
        >.daz-sidebar__dropdown{
            display: inline-block;
            position: absolute;
            background: #fff;
            left: 100%;
           // width: max-content;
            top: 0;
            overflow: auto;
            // width: 500px;
            // min-width: 200px;
            width: fit-content;

        }
        >.daz-sidebar__dropdown_child_1{
            display: block;
            position: absolute;
            background: #fff;
            left: 200%;
           // width: max-content;
            top: 0;
            overflow: auto;
            width: 500px;

        }
        >.daz-sidebar__dropdown_child_1{
            
            left: 200%;
           // width: max-content;
            top: 0;

        }
    }
    &__dropdown-wrp:hover{
        >.daz-sidebar__dropdown{
            display: inline-block;
            position: absolute;
            background: #fff;
            right:100%; 
           // width: max-content;
            top: 0;
            overflow: auto;
            // width: 500px;
            width: fit-content;

        }
        >.daz-sidebar__dropdown_child_1{
            display: block;
            position: absolute;
            background: #fff;
            left: 200%;
           // width: max-content;
            top: 0;
            overflow: auto;
            width: 500px;

        }
        >.daz-sidebar__dropdown_child_1{
            
            left: 200%;
           // width: max-content;
            top: 0;

        }
    }
    
    .subprocess-dropdown{
        border: solid 1px $dropdown-arrow-grey;
    }

    &__dropdown-click{
        cursor: pointer;
        padding-left: 13px;
        width: 100%;
        color: $dropdown-txt-grey; 

    }
    &__clickdropdown{
        display: none;
    }
    &__dropdown-click.active + .daz-sidebar__clickdropdown{
            display: block;
            position: absolute;
            left: 100%;
            width: 100%;
            background: #fff;
            top: 0;
    }

    &__hoverinfo{
        
        z-index: 3;
        position: absolute;
        background: #fff;
        top: 10px;
        left: -99999px;
        // padding: 10px;
        min-width: 170px;
        // max-height: 290px;
        padding-right: 0px;
        padding-left: 0px;
        // overflow-y: auto;
        text-align: left;
        white-space: nowrap;
        &--item{
            display: block;
            // padding-left: 10px;
            padding-right: 10px;
            // margin-top: 10px;
            // margin-bottom: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: $dropdown-txt-grey;
        }

        &--active{
           // background-color: $grey-bg;
           font-weight: $font-bold; 

        }
        a,&--click{
            cursor: pointer;
            text-decoration: none;
            &:hover{
                text-decoration: none;

            }
            font-weight: $font-light;
            color: $dropdown-txt-grey;
            padding: 5px 13px;
            width: 100%;
        }
      
    }

    &__processListing{
    //    transition: left 0.1s ease 0.8s;
    }
    
}

.sidebar {
    // width: 250px;
    background-color: #fff;
    // padding: 10px;
  }
  
  .menu-item {
    position: relative;
    margin-bottom: 10px;
  }
  .menu-item:hover{
    background-color: $grey-bg;
  }
  .menu-item a,
  .child-link,
  .nested-child-link,
  .nested-child-child-link {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333; /* Default text color */
  }
  
  .menu-item a:hover,
  .child-link:hover,
  .nested-child-link:hover,
  .nested-child-child-link:hover {
    background-color: $grey-bg; /* Hover background color */
  }
  
  .child-menu {
    display: none;
    position: absolute;
    left: 100%; /* Positioning next to the parent menu */
    top: 0;
    background-color: #fff; /* Same background as parent */
    // border: solid 1px $dropdown-arrow-grey;
    z-index: 1; /* Ensure it's above other content */
  }
  
  .menu-item:hover .child-menu {
    display: block; /* Show child menu on hover */
  }
  
  .child-item {
    position: relative; /* For nested child menu */
  }
  .nested-child-item:hover,
  .child-item:hover{
    background-color: $grey-bg;
  }
  .nested-child-menu {
    display: none;
    position: absolute;
    left: 100%; /* Positioning next to Child Menu 1 */
    top: 0;
    background-color: #fff; /* Same background as parent */
    // border: solid 1px $dropdown-arrow-grey;
    z-index: 1; /* Ensure it's above other content */
  }
  
  .child-item:hover .nested-child-menu {
    display: block; /* Show nested child menu on hover */
  }
  
  .nested-child-child-menu {
    display: none;
    position: absolute;
    left: 100%; /* Positioning next to Nested Child 1 */
    top: 0;
    background-color: #fff; /* Same background as parent */
    // border: solid 1px $dropdown-arrow-grey;
    z-index: 1; /* Ensure it's above other content */
  }
  
  .nested-child-menu:hover .nested-child-child-menu {
    display: block; /* Show nested child of nested child menu on hover */
  }
  
  .active {
    font-weight: bold;
    color: blue; /* Customize active link style */
  }
  
.nested-child-child-item-list{
    height: 40px !important;
    // width: max-content;
    // padding-right: 10px;
    // min-width: 200px;
}
// .menu-parent:hover,
.nested-child-child-item-list:hover {
    background-color: $grey-bg; /* Hover background color */
  }
  .menu-parent{
    width: fit-content;
    background-color: #fff;
  }
  